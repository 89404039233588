<template>
    <div>
        <v-layout wrap justify-center class="mainfont">
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
                spinner="spinner" />
            <v-flex v-if="list.length > 0" xs12>
                <v-layout wrap justify-center>
                    <v-flex xs12 pl-6 pt-5>
                        <span style="font-size: 25px;font-weight: bolder;">REPORTS</span>
                    </v-flex>
                </v-layout>

              
             

            </v-flex>
            <v-flex xs12 v-else text-center style="font-size: 25px;font-weight: bolder;" pt-6>
                No Report's found!
            </v-flex>


        </v-layout>





    </div>
</template>
<script>
import axios from "axios";

export default {


    data() {
        return {
            name: null,
            barlist: [],
            userProfileTabs: null,
            subtab: null,









            appLoading: false,
            page: 1,
            pages: 0,
            dialog1: false,

            preview: null,
            tab: null,
            barlist1: [],
            dialog3: false,
            deletedialog: false,
            editdailog: false,

            minAmount: null,
            password: null,
            categoryarray: [],
            list: [],
            flag: false,
            flag2: false,
            auctionbased: [],
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            limit: 10,
            fromDate: null,
            toDate: null,
            fromDate2: null,
            toDate2: null,
            noOfRooms: null,

            accName: null,
            rangeid: null,
            maxGuestPerRoom: null,


            msg: null,
            currentPage: 1,

            showSnackBar: false,
            curid: [],
            dialog2: false,


        };
    },
    watch: {


        currentPage() {
            this.getList();
        },
    },

    mounted() {
        this.getList();


    },


    methods: {
        deleteItem() {
            axios({
                url: "/delete/accomodation",
                method: "POST",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    accid: this.curid,
                }
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.deletedialog = false;
                        window.location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

        edit() {
            axios({
                method: "POST",
                url: "/edit/accomodation",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    noOfRooms: this.curid.noOfRooms,
                    accName: this.curid.accName,
                    maxGuestPerRoom: this.curid.maxGuestPerRoom,
                    accid: this.curid._id,

                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.editdailog = false;
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },

        addIb() {
            axios({
                method: "POST",
                url: "/add/accomodation",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    noOfRooms: this.noOfRooms,
                    accName: this.accName,
                    maxGuestPerRoom: this.maxGuestPerRoom,

                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.dialog2 = false;
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },

        getList() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/all/accomodations",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    status: this.status,
                    categoryId: this.categoryId,
                    page: this.currentPage,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    limit: 10,
                },
            })
                .then((response) => {
                    this.list = response.data.data;
                    this.totalData = response.data.totalLength;
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })

                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },

    },
};
</script>
  
<style >
.cus3 {
    height: 50px;
    background-color: white;
}

.vertical-divider {
    border-right: 1px solid black;
    height: 70px;
}

.v-date-picker .v-picker__body {
    box-shadow: none;
}

#chart-container {
    background: linear-gradient(180deg, #29807c 0%, rgba(5, 112, 107, 0.16) 100%);
}

/* Add some styling to the cards */
.elevation-3 {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: 0.3s box-shadow;
}

.elevation-3:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.headline {
    font-size: 18px;
    font-weight: bold;
}
</style>